import React from 'react'

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import slideImg_1 from "../../images/slider/1.jpg"
import slideImg_2 from "../../images/slider/2.jpg"
// import slideImg_3 from "../../images/slider/3.jpg"
import slideImg_4 from "../../images/slider/4.jpg"
import slideImg_5 from "../../images/slider/5.jpg"
import slideImg_6 from "../../images/slider/6.jpg"
import slideImg_7 from "../../images/slider/7.jpg"


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function Slider() {
    

    return (
        <Swiper
          spaceBetween={50}
          navigation
          pagination={{ clickable: true }}
          autoplay = {{
            delay: 3000
          }}
        >
            {
              [slideImg_1, slideImg_2, slideImg_4, slideImg_5, slideImg_6, slideImg_7].map(img => {
                return (
                  <SwiperSlide>
                    <div class="swiper-slide">
                      <img src={img} alt="" class="" style={{width: "100%", borderRadius: 6}}/>
                    </div>
                  </SwiperSlide>
                )
              })

            }
        </Swiper>
    )
}
