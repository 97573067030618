import React, { useState, useEffect } from "react";
import { useHttp } from "../hooks/http.hook";

export const SettingsContext = React.createContext({});

const SettigsProvider = (props) => {
  const [settings, setSettings] = useState({});
  const { request } = useHttp();

  async function getSetting() {
    let data = await request("/settings", "GET");

    setSettings(data);
  }

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettigsProvider;
