import './index.sass'

const Dialog = (props) => {

    const onClose = event => {
        if (event.target.className === 'dialog') {
            console.log('close Dialog')
            props.onClose()
        }
    }

    if (props.open) {
        return (
            <div className='dialog' onClick={(event) => {onClose(event)}}>
                <div className="dialog__content">
                    {props.children}
                </div>
            </div>
        )
    } else {
        return(<></>)
    }
}

export default Dialog