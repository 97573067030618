import { useState } from 'react'

import Dialog from "../components/Dialog"
import './index.sass'
import TextareaAutosize from '@mui/material/TextareaAutosize';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ru from 'react-phone-number-input/locale/ru.json'
import { useHttp } from "../../hooks/http.hook";

const ContactUs = (props) => {
    const { request } = useHttp();

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const submit = (event) => {
        event.preventDefault()
        console.log('Submit')

        request('/forms/contact', 'POST', {name, phone, email, question: message})

        props.onClose()
        setName('')
        setPhone('')
        setEmail('')
        setMessage('')
    }


    return (
        <Dialog 
            open={props.open}
            onClose={props.onClose}
        >
            <div className="form">
                <div className="form__close" onClick={props.onClose}>
                    <span></span>
                </div>
                <h2 className="form__title">СВЯЗАТЬСЯ С НАМИ</h2>
                <div className="form__content">
                    <div className="form__text">
                        Заполните форму и менеджер свяжется с Вами в ближайшее время. 
                    </div>
                    <form onSubmit={event => { submit(event) }}>
                        <div className="form__inputs-group">
                            <input 
                                value={name}
                                onChange={event => {setName(event.target.value)}}
                                required 
                                type="text" 
                                className="form__input form__input-name" 
                                placeholder="ФИО*"
                            />
                        
                            <PhoneInput
                                required
                                className="form__input form__input-phone"
                                labels={ru}
                                placeholder="Номер телефона*"
                                defaultCountry="RU"
                                value={phone}
                                onChange={setPhone}
                            />
                        </div>

                        <input 
                            value={email}
                            onChange={event => {setEmail(event.target.value)}}
                            required 
                            type="email" 
                            className="form__input form__input-email" 
                            placeholder="Почта или иные способы связаться с Вами*"
                        />

                        <TextareaAutosize
                            value={message}
                            onChange={event => {setMessage(event.target.value)}}
                            required
                            className='form__input form__input-massage'
                            aria-label="message"
                            placeholder="Задайте свой вопрос*"
                        />

                        <input required type="submit" className='form__btn-submit'/>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default ContactUs