import { useState, useEffect } from "react"

import Header from "../../../components/Header"
import Container from "@material-ui/core/Container"
import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Typography from '@mui/material/Typography';


import { useHttp } from "../../../../hooks/http.hook";

const SetCharacteristicsTemplate = () => {
    const { request } = useHttp();

    const [characteristics, setCharacteristics] = useState([['', '']])

    const [name, setName] = useState('') 

    const [errorTitle, setErrorTitle] = useState(false)
    const [errorCharacteristics, setErrorCharacteristics] = useState([])

    const deleteCharacteristic = (index) => {
        const char = Array.from(characteristics)
        char.splice(index, 1)
        setCharacteristics(char)
    }

    const addCharacteristic = () => {
        const char = Array.from(characteristics)
        char.push(['', ''])
        setCharacteristics(char)
    }

    const saveTemplate = () => {
        let valid = true
        if (name.length === 0) {
            setErrorTitle(true)
            valid = false
        }

        let errChar = []
        characteristics.forEach((characteristic, index) => {
            if (characteristic[0].length === 0) {
                errChar.push(index)
                valid = false
            }
        })
        setErrorCharacteristics(errChar)

        if (valid) {
            let values = {}
            characteristics.forEach(characteristic => {
                values[characteristic[0]] = characteristic[1]
            })

            request('/characteristics-template/', 'PUT', {id: window.location.pathname.split('/')[3], name, values})
            window.location.href = '/admin/characteristics-template/'
        }
    }


    async function getTemplate() {
        const id = window.location.pathname.split('/')[3]
        const temp = await request('/characteristics-template/' + id, "GET")

        console.log(temp)

        setName(temp.name)
        setCharacteristics(Object.entries(temp.values))
    }

    useEffect(async () => {
        await getTemplate()
    }, [])

    return (
        <>
            <Header title='Изменение шаблона'/>

            <Container style={{paddingTop: '2rem'}}>
                <TextField
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value)
                        setErrorTitle(false)
                    }}
                    error={errorTitle}
                    id="outlined-basic"
                    label="Название шаблона"
                    variant="outlined"
                    style={{width: '100%', marginBottom: '2rem'}}
                />
                <Typography>Характеристики</Typography>

                {
                    characteristics.map((characteristic, index) => {
                        return (
                            <div className='characteristic-row'>
                                <TextField
                                    value={characteristic[0]}
                                    onChange={event => {
                                        const char = Array.from(characteristics)
                                        char[index][0] = event.target.value
                                        setCharacteristics(char)

                                        let errChar = Array.from(errorCharacteristics)
                                        if (errChar.indexOf(index) != -1) {
                                            errChar.splice(errChar.indexOf(index), 1)
                                        }
                                        setErrorCharacteristics(errChar)
                                    }}
                                    error={errorCharacteristics.indexOf(index) != -1}
                                    id="outlined-basic"
                                    label="Название"
                                    variant="outlined"
                                    style={{width: '48%', margin: '1rem 0'}}
                                />

                                <Typography style={{margin: '0 1rem'}}>-</Typography>
                                
                                <TextField
                                    value={characteristic[1]}
                                    onChange={event => {
                                        const char = Array.from(characteristics)
                                        char[index][1] = event.target.value
                                        setCharacteristics(char)
                                    }}
                                    id="outlined-basic"
                                    label="Значение по умолчанию"
                                    variant="outlined"
                                    style={{width: '48%', margin: '1rem 0'}}
                                />

                                <Button onClick={() => {deleteCharacteristic(index)}} variant="contained" style={{backgroundColor: "#f44336", color: '#fff', marginLeft: '1rem'}}>
                                    Удалить
                                </Button>
                            </div>
                        )
                    })
                }

                <Button 
                    onClick={addCharacteristic} 
                    variant="contained"
                    style={{backgroundColor: "#66bb6a", color: '#fff', display: 'block', margin: '2rem auto'}}
                > Добавить </Button>

                <Button 
                    onClick={saveTemplate} 
                    variant="contained"
                    style={{backgroundColor: "#66bb6a", color: '#fff', display: 'block', margin: '4rem 0 0 auto', padding: '1rem 3rem'}}
                > Сохранить </Button>
            </Container>
        </>
    )
}

export default SetCharacteristicsTemplate