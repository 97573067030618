import './index.sass'


const SectionTitle = (props) => {
    return (
        <div className="section-title-block">
            <h2 className="section-title">
                {props.title}
            </h2>
        </div>
    )
}

export default SectionTitle