import { useState } from "react"

import Logo from "../../../images/logo.png"
import "./index.sass"
import { Link } from "react-router-dom"
import ContactUs from "../../forms/ContactUs"
import Order from "../../forms/Order"

const Header = () => {
    const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false)
    const [orderDialogOpen, setOrderDialogOpen] = useState(false)

    const [sideBar, setSideBar] = useState(false)

    return (
        <header className="header">
            <div className="container">
                <div className="header__top">
                    <div className="logo header__logo">
                        <Link to="/">
                            <img src={Logo} alt="" />
                        </Link>
                    </div>
                    <div className="header__buttons">
                        <button 
                            className="button header__contact-btn"
                            onClick={() => {setContactUsDialogOpen(true)}}
                        >
                            СВЯЗАТЬСЯ С НАМИ
                        </button>
                        <button 
                            className="button header__order-btn"
                            onClick={() => {setOrderDialogOpen(true)}}
                        >
                            СДЕЛАТЬ ЗАКАЗ
                        </button>
                    </div>
                    <div className='burger-menu-box' onClick={() => {setSideBar(true)}}>
                        <div className="burger-menu"></div>
                    </div>
                </div>
                <div className="header__bottom">
                    <nav className="nav">
                        <Link to="/">
                            <div className="button nav__link">
                                ГЛАВНАЯ
                            </div>
                        </Link>
                        <Link to="/catalog">
                            <div className="button nav__link">
                                КАТАЛОГ
                            </div>
                        </Link>
                        <Link to="/contacts">
                            <div className="button nav__link">
                                КОНТАКТЫ
                            </div>
                        </Link>
                        <Link to="/info">
                            <div className="button nav__link">
                                О НАС
                            </div>
                        </Link>
                    </nav>
                </div>
            </div>
            <div className={"side-bar" + (sideBar ? ' side-bar--active' : '')}>
                <div className="container" style={{height: "100vh"}}>
                    <Link to="/" onClick={() => {setSideBar(false)}}>
                        <div className="side-bar__logo">
                            <img src={Logo} alt=""/>
                        </div>
                    </Link>

                    <div className="side-bar__nav">
                        <Link to="/">
                            <div className="button side-bar__link">
                                ГЛАВНАЯ
                            </div>
                        </Link>

                        <Link to="/catalog">
                            <div className="button side-bar__link">
                                КАТАЛОГ
                            </div>
                        </Link>
                        <Link to="/contacts">
                            <div className="button side-bar__link">
                                КОНТАКТЫ
                            </div>
                        </Link>
                        <Link to="/info">
                            <div className="button side-bar__link">
                                О НАС
                            </div>
                        </Link>
                    </div>
                    <div className="side-bar__close" onClick={() => {setSideBar(false)}}>
                        <span></span>
                    </div>
                </div>
            </div>
            <ContactUs
                open={contactUsDialogOpen}
                onClose={() => {setContactUsDialogOpen(false)}}
            />
            <Order 
                open={orderDialogOpen}
                onClose={() => {setOrderDialogOpen(false)}}
            />
        </header>
    )
}

export default Header