import Header from '../components/Header/Header'
import Slider from './Slider.js'
import Footer from '../components/Footer'
import Info from './Info'
import Contacts from '../components/Contacts'
import SectionTitle from "../components/SectionTitle"

import "./index.sass"
import Catalog from './Catalog'

export const Main = () => {
    return (
        <div className="page">
            <Header />

            <main>
                <div className="container">
                    <div className="slider">
                        <Slider />
                    </div>
                </div>
                
                <Catalog />

                <Info />

                <div className="container" style={{marginTop: "2.3rem"}}>
                    <SectionTitle title='КОНТАКТЫ' />
                </div>

                <Contacts />
            </main>
            
            <Footer />
        </div>
    )
}