import { useState } from 'react'
import SectionTitle from '../components/SectionTitle'
import ContactUs from '../forms/ContactUs';


const Info = () => {
    const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false)

    return (
        <section>
            <div className="container">
                <SectionTitle title='О НАС' />
                <div className="main-page-info__content">
                    <div className="main-page-info__text">
                    ООО “МАГИСТРАЛЬ АВТО” основано 28 июля 2016 года. 
                    Компания занимается реализацией специализированной 
                    грузовой и серийной техники КаМАЗ, МАЗ, ISUDZU и других брендов,
                    а также всего модельного ряда прицепов т полуприцепов
                    различного назначения. ООО “МАГИСТРАЛЬ АВТО” является партнером 
                    заводов изготовителей специализированной техники,
                    партнерами многих лизинговых компаний,
                    таких как: Альфа Лизинг, Сбербанк Лизинг, ВЕБ лизинг, ВТБ Лизинг, 
                    Европлан и многие другие.
                    </div>
                </div>
                <div onClick={() => {setContactUsDialogOpen(true)}} className="button main-page-info__btn-contact">СВЯЗАТЬСЯ</div>
            </div>
            <ContactUs
                open={contactUsDialogOpen}
                onClose={() => {setContactUsDialogOpen(false)}}
            />
        </section>
    )
}

export default Info