import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Header from "../../../components/Header"
import Container from "@material-ui/core/Container"
import { Button } from '@material-ui/core';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



import { useHttp } from "../../../../hooks/http.hook";
import "./index.sass"

const Catalog = () => {
    const { request } = useHttp();

    const [products, setProducts] = useState([])

    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [pretenderRemoval, setPretenderRemoval] = useState(null);

    const handleOpenDialogDelete = (product) => {
        setPretenderRemoval(product)
        setOpenDialogDelete(true);
    };

    const handleCloseDialogDelete = () => {
        setOpenDialogDelete(false);
    };

    const deleteProduct = async () => {
        handleCloseDialogDelete()
        await request('/product/', "DELETE", {id: pretenderRemoval._id})
        await getProducts()
    }

    const visibilityProduct = async (id, value) => {
        await request('/product/visibility', "POST", {id, visibility: value})
        await getProducts()
    }
    
    async function getProducts() {
        let data = await request('/product/', "GET")
        data = data.filter(product => product.visibility).concat(data.filter(product => !product.visibility))
        setProducts(data)
    }

    useEffect(() => {
        getProducts()
    }, [])

    return (
        <>
            <Header title="Каталог">
                <Link to='/admin/catalog/add-product'>
                    <Button variant="contained" style={{backgroundColor: "#66bb6a", color: '#fff', marginLeft: '1rem'}}>
                        Добавить
                    </Button>
                </Link>
            </Header>

            <Container style={{marginTop: '2rem'}}>
                {
                    products.map(product => {
                        return (
                            <Accordion key={product._id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <div className='product-card'>
                                        <img src={"/uploads/" + product.images[0]} className='product-card__img' alt="" />
                                        <h3 className='product-card__title'>{product.title}</h3>
                                    </div>
                                        { !product.visibility && 
                                            <div className='product-card__visibility' style={{color: 'red'}}>Скрыто</div>
                                        }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='product-card__discription'>{product.discription}</div>
                                    <div className='product-card__price'>{product.price}руб.</div>

                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '2rem'}}>
                                        
                                        <Button onClick={() => {visibilityProduct(product._id, product.visibility ? false : true)}} variant="contained" style={{backgroundColor: "#33bfff", color: '#fff'}}>
                                            {product.visibility ? 'Скрыть' : 'Опубликовать'}
                                        </Button>

                                        <div className='btn-group'>
                                            <Link to={'/admin/catalog/' + product._id}>
                                                <Button variant="contained" style={{backgroundColor: "#66bb6a", color: '#fff'}}>
                                                    Изменить
                                                </Button>
                                            </Link>
                                            <Button onClick={() => {handleOpenDialogDelete(product)}} variant="contained" style={{backgroundColor: "#f44336", color: '#fff', marginLeft: '1rem'}}>
                                                Удалить
                                            </Button>
                                        </div>
                                    </div>
                                </AccordionDetails>

                            </Accordion>
                            
                        )
                    }) 
                }

                
                
            </Container>

            <Dialog
                open={openDialogDelete}
                onClose={handleCloseDialogDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Удаление продукта {pretenderRemoval && pretenderRemoval.name}
                </DialogTitle>
                <DialogContent>
                    Уверены, что хотите удалить продукт "{pretenderRemoval && pretenderRemoval.name}"?
                </DialogContent>
                <DialogActions>

                    <Button 
                        onClick={deleteProduct} 
                        variant="contained"
                        style={{backgroundColor: "#66bb6a", color: '#fff'}}
                    > Да </Button>

                    <Button 
                        onClick={handleCloseDialogDelete} 
                        variant="contained"
                        style={{backgroundColor: "#f44336", color: '#fff'}}
                    > Назад </Button>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default Catalog