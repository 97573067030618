import React, { useState, useEffect } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { useHttp } from "../../../hooks/http.hook";
import Header from "../../components/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100ch",
    },
  },
  input: {
    width: "100%",
    margin: "1rem 0",
  },
  map: {
    flexBasis: "50%",
    margin: "0 1rem",
    maxWidth: "34.3rem",
    height: "20.7857rem",
    marginTop: "2.357rem",
    border: "3px solid #00477f",
    borderRadius: "5px",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Settings = () => {
  const classes = useStyles();
  const { request } = useHttp();

  const [values, setValues] = React.useState({
    tel: "",
    address: "",
    email: "",
    latitude: "",
    longitude: "",
  });
  const [alert, setAlert] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  async function getSetting() {
    let data = await request("/settings/", "GET");
    let state = values;
    console.log(data);

    for (let elem of [
      "tel",
      "address",
      "email",
      "latitude",
      "longitude",
      "emailContacts",
    ]) {
      if (elem in data) {
        state = { ...state, [elem]: data[elem] };
      }
    }

    setValues(state);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {

      let data = [
        {
          type: "tel",
          value: values.tel,
        },
        {
          type: "address",
          value: values.address,
        },
        {
          type: "email",
          value: values.email,
        },
        {
          type: "latitude",
          value: values.latitude,
        },
        {
          type: "longitude",
          value: values.longitude,
        },
        {
          type: "emailContacts",
          value: values.emailContacts,
        },
      ];

      await request("/settings/", "PUT", data);

      setAlert(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <>
      <Header title="Настройки" />
      <Container maxWidth="sm">
        <form
          onSubmit={(event) => handleSubmit(event)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <h2> Общие </h2>
          <TextField
            className={classes.input}
            id="outlined-basic"
            label="Телефон"
            variant="outlined"
            value={values.tel}
            onChange={handleChange("tel")}
          />

          <TextField
            className={classes.input}
            id="outlined-basic"
            label="Адрес"
            variant="outlined"
            value={values.address}
            onChange={handleChange("address")}
          />

          <TextField
            className={classes.input}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            value={values.email}
            onChange={handleChange("email")}
          />

          <TextField
            className={classes.input}
            id="outlined-basic"
            label="Email на которой будут приходить письма с формы на странице контактов"
            variant="outlined"
            value={values.emailContacts}
            onChange={handleChange("emailContacts")}
          />

          <h2> Координаты точки на карте на странице контактов </h2>

          <TextField
            className={classes.input}
            id="outlined-basic"
            label="Широта"
            variant="outlined"
            value={values.latitude}
            onChange={handleChange("latitude")}
          />

          <TextField
            className={classes.input}
            id="outlined-basic"
            label="Долгота"
            variant="outlined"
            value={values.longitude}
            onChange={handleChange("longitude")}
          />

          <YMaps>
            <div
              className={"map " + classes.map}
              style={{ margin: "2rem auto" }}
            >
              <Map
                state={{
                  center: [values.latitude, values.longitude],
                  zoom: 13,
                }}
                defaultState={{
                  center: [values.latitude, values.longitude],
                  zoom: 13,
                }}
                width="100%"
                height="100%"
              >
                <Placemark
                  geometry={[values.latitude, values.longitude]}
                  defaultProperties={{
                    balloonContent: "Мы",
                  }}
                  modules={["geoObject.addon.balloon"]}
                />
              </Map>
            </div>
          </YMaps>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{
              display: "block",
              margin: "auto",
              marginBottom: "7rem",
              width: "25%",
            }}
          >
            Сохранить
          </Button>
        </form>

        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Сохранено
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default Settings;
