import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Header from '../components/Header/Header'
import Footer from '../components/Footer'
import SectionTitle from "../components/SectionTitle"

import { useHttp } from "../../hooks/http.hook";

export const Catalog = () => {
    const { request } = useHttp();

    const [products, setProducts] = useState([])

    async function getProducts() {
        let data = await request('/product/', "GET")
        data = data.filter(product => product.visibility)
        setProducts(data)
    }

    useEffect(() => {
        getProducts()
    }, [])

    return (
        <div className="page">
            <div>
                <Header/>
                <div className="container" style={{marginTop: "2.3rem"}}>
                    <SectionTitle title='КАТАЛОГ ТЕХНИКИ' />
                </div>
            </div>

            <main>
                <div className="container">
                    <div className="main-page-catalog__cards">
                        {
                            products.length > 0 ? 
                                products.map(product => {
                                    return (
                                        <Link to={"/catalog/" + product._id} className="main-page-catalog__card">
                                            <div>
                                                <img src={"/uploads/" + product.images[0]} alt="" />
                                            </div>
                                            <span>{product.title}</span>
                                        </Link>
        
                                    )
                                }) :
                            <>
                                <div className="main-page-catalog__card main-page-catalog__card--load">
                                    <div>
                                        <img src="" alt="" />
                                    </div>
                                    <span></span>
                                </div>
                                <div className="main-page-catalog__card main-page-catalog__card--load">
                                    <div>
                                        <img src="" alt="" />
                                    </div>
                                    <span></span>
                                </div>
                                <div className="main-page-catalog__card main-page-catalog__card--load">
                                    <div>
                                        <img src="" alt="" />
                                    </div>
                                    <span></span>
                                </div>
                                <div className="main-page-catalog__card main-page-catalog__card--load">
                                    <div>
                                        <img src="" alt="" />
                                    </div>
                                    <span></span>
                                </div>
                                <div className="main-page-catalog__card main-page-catalog__card--load">
                                    <div>
                                        <img src="" alt="" />
                                    </div>
                                    <span></span>
                                </div>
                                <div className="main-page-catalog__card main-page-catalog__card--load">
                                    <div>
                                        <img src="" alt="" />
                                    </div>
                                    <span></span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}
