import { useContext, useEffect } from 'react'

import { SettingsContext } from "../../../Context/SettingsContext"

import './index.sass'

const Footer = () => {
    const settings = useContext(SettingsContext) 

    return (
        <footer className='footer'>
            <div className="container">
                <div className="footer__top-line"></div>

                <div className="footer__content">
                    <div className="footer__left">
                        <span className="footer__title">ООО “МАГИСТРАЛЬ АВТО”</span>
                        <span className="footer__tel">Телефон: <a href={"tel:" + settings.tel}>{settings.tel}</a></span>
                        <span className="footer__email">Почта: <a href={"mailto:" + settings.email}>{settings.email}</a></span>
                    </div>
                    <div className="footer__right">
                        <span className="footer__address">{settings.address}</span>
                    </div>
                </div>
            </div>
        </footer>  
    )
}

export default Footer