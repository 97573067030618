import React, { useState, useEffect } from 'react'

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login"

import { useHttp } from '../hooks/http.hook'
import Settings from './views/Settings';
import Catalog from './views/Catalog/AllProducts';
import Product from './views/Catalog/Product';
import AddProduct from './views/Catalog/AddProduct';
import CharacteristicsTemplate from './views/CharacteristicsTemplate/AllTemplates'
import AddCharacteristicsTemplate from './views/CharacteristicsTemplate/AddTemplate';
import SetCharacteristicsTemplate from './views/CharacteristicsTemplate/Template';

const AdminRouter = () => {
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setLoading] = useState(true)
  const { request } = useHttp()
  
  const checkToken = async () => {
    try {
      const isAdmin = await request('/auth/is-admin', 'POST', {}, false)

      if (isAdmin) {
        setIsAdmin(true)
        setLoading(false)
      } else {
        localStorage.removeItem('auth_token')
        setLoading(false)
      }

    } catch (e) {
      console.log(e);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('auth_token')) {
      checkToken()
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) {
    return (
      <>
        <div className='lds-container'>
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
      </>
    )
  } else if (isAdmin) {
    return (
      <Router>
        <Switch>
          <Route exact path="/admin" component={Settings} />
          <Route exact path="/admin/catalog" component={Catalog} />
          <Route exact path="/admin/catalog/add-product" component={AddProduct} />
          <Route exact path="/admin/catalog/*" component={Product} />
          <Route exact path="/admin/characteristics-template" component={CharacteristicsTemplate} />
          <Route exact path="/admin/characteristics-template/create" component={AddCharacteristicsTemplate} />
          <Route exact path="/admin/characteristics-template/*" component={SetCharacteristicsTemplate} />
        </Switch>
      </Router>
    )
  } else {
    return (
      <Router>
        <Switch>
          <Route exact path="/admin" component={Login} />

          <Route exact path="/admin/*" component={Login} />
        </Switch>
      </Router>
    )
  }
  
};

export default AdminRouter;