import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import SectionTitle from '../components/SectionTitle';

import { useHttp } from "../../hooks/http.hook";

export default function Catalog() {
    const { request } = useHttp();

    const [products, setProducts] = useState([])

    async function getProducts() {
        let data = await request('/product/mainpage', "GET")
        setProducts(data)
    }

    useEffect(() => {
        getProducts()
    }, [])

    return (
        <div className="container main-page-catalog">
            <SectionTitle title='КАТАЛОГ ТЕХНИКИ'/>

            <div className="main-page-catalog__cards">
                {
                    products.map(product => {
                        return (
                            <Link to={"/catalog/" + product._id} className="main-page-catalog__card">
                                <div>
                                    <img src={"/uploads/" + product.images[0]} alt="" />
                                </div>
                                <span>{product.title}</span>
                            </Link>

                        )
                    })
                }
            </div>
            <Link to="/catalog" className="button main-page-catalog__bottom-btn">
                ПЕРЕЙТИ В КАТАЛОГ
            </Link>
        </div>
    )
}
