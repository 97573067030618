import Header from '../components/Header/Header'
import Contacts from '../components/Contacts'
import Footer from '../components/Footer'
import SectionTitle from "../components/SectionTitle"

export const ContactsPage = () => {
    return (
        <div className="page">
            <div>
                <Header />
                <div className="container" style={{marginTop: "2.3rem"}}>
                    <SectionTitle title='КОНТАКТЫ' />
                </div>
            </div>

            <main>
                <Contacts />
            </main>

            <Footer />
        </div>
    )
}