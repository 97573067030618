import { useState } from "react"

import Header from '../components/Header/Header'
import Footer from '../components/Footer'
import SectionTitle from '../components/SectionTitle'
import ContactUs from '../forms/ContactUs'

import './index.sass'

export const Info = () => {
    const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false)

    return (
        <div className="page">
            <div>
                <Header />
                <div className="container" style={{marginTop: "2.3rem"}}>
                    <SectionTitle title='О НАС' />
                </div>
            </div>

            <main>
                <div className="container">
                    <div className="info__content">
                        <div className="info__text">
                        ООО “МАГИСТРАЛЬ АВТО” основано 28 июля 2016 года. 
                        Компания занимается реализацией специализированной 
                        грузовой и серийной техники КаМАЗ, МАЗ, ISUDZU и так далее,
                        а также всего модельного ряда прицепов и полуприцепов
                        различного назначения. ООО “МАГИСТРАЛЬ АВТО” является партнером 
                        заводов изготовителей специализированной техники,
                        партнерами многих лизинговых компаний,
                        таких как: Альфа Лизинг, Сбербанк Лизинг, ВЕБ лизинг, ВТБ Лизинг, 
                        Европлан и многие другие.
                        </div>
                        <div className="button info__btn-contact" onClick={() => {setContactUsDialogOpen(true)}}>СВЯЗАТЬСЯ</div>
                    </div>
                </div>

                <ContactUs
                    open={contactUsDialogOpen}
                    onClose={() => {setContactUsDialogOpen(false)}}
                />
            </main>

            <Footer />
        </div>
    )
}