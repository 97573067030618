import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Main, Catalog, ContactsPage, Info, Product } from './Pages'
import AdminRouter from "./Admin/router"

import SettingsProvider from "./Context/SettingsContext";

const App = () => {
  return (
    <SettingsProvider>
      <Router>
        <Switch>
          <Route exact path="/admin" component={ AdminRouter } />
          <Route exact path="/admin/*" component={ AdminRouter } />

          
          <Route exact path="/catalog" component={ Catalog } />
          <Route exact path="/catalog/*" component={ Product } />

          <Route path="/contacts" component={ ContactsPage } />
          
          <Route path="/info" component={ Info } />
          
          <Route path="/*" component={ Main } />
        </Switch>
      </Router>
    </SettingsProvider>
  )
}

export default App;
