import { useState, useEffect } from 'react'

import Header from "../../../components/Header"
import Characteristics from '../../../components/Characteristics';
import Container from "@material-ui/core/Container"
import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Typography from '@mui/material/Typography';

import { useHttp } from "../../../../hooks/http.hook";

import './index.sass'

const AddCharacteristicsTemplate = () => {
    const { request } = useHttp();

    const [characteristics, setCharacteristics] = useState([['', '']])

    const [name, setName] = useState('') 

    const [errorTitle, setErrorTitle] = useState(false)
    const [errorCharacteristics, setErrorCharacteristics] = useState([])

    const deleteCharacteristic = (index) => {
        const char = Array.from(characteristics)
        char.splice(index, 1)
        setCharacteristics(char)
    }

    const addCharacteristic = () => {
        const char = Array.from(characteristics)
        char.push(['', ''])
        setCharacteristics(char)
    }

    const isCharacteristicsValid = () => {
        let errChar = []
        characteristics.forEach((characteristic, index) => {
            if (characteristic[0].length === 0) {
                errChar.push(index)
            }
        })
        setErrorCharacteristics(errChar)

        return errChar.length === 0
    }

    const saveTemplate = () => {
        let valid = true

        valid = isCharacteristicsValid()

        if (name.length === 0) {
            setErrorTitle(true)
            valid = false
        }

        
        if (valid) {
            let values = {}
            characteristics.forEach(characteristic => {
                values[characteristic[0]] = characteristic[1]
            })

            request('/characteristics-template/create', 'POST', {name, values})
            window.location.href = '/admin/characteristics-template/'
        }
    }

    return (
        <>
            <Header title="Создание шаблона"/>

            <Container style={{paddingTop: '2rem'}}>
                <TextField
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value)
                        setErrorTitle(false)
                    }}
                    error={errorTitle}
                    id="outlined-basic"
                    label="Название шаблона"
                    variant="outlined"
                    style={{width: '100%', marginBottom: '2rem'}}
                />
                <Typography>Характеристики</Typography>

                <Characteristics
                    characteristics={characteristics}
                    setCharacteristics={setCharacteristics}
                    errorCharacteristics={errorCharacteristics}
                    setErrorCharacteristics={setErrorCharacteristics}
                    deleteCharacteristic={deleteCharacteristic}
                    addCharacteristic={addCharacteristic}
                />

                <Button 
                    onClick={saveTemplate} 
                    variant="contained"
                    style={{backgroundColor: "#66bb6a", color: '#fff', display: 'block', margin: '4rem 0 0 auto', padding: '1rem 3rem'}}
                > Создать </Button>
            </Container>
        </>
    )
}

export default AddCharacteristicsTemplate