import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Typography from '@mui/material/Typography';


const Characteristics = (props) => {
    const {
        characteristics, 
        setCharacteristics, 
        errorCharacteristics, 
        setErrorCharacteristics, 
        deleteCharacteristic, 
        addCharacteristic
    } = props

    return (
        <>
            {
                characteristics.map((characteristic, index) => {
                    return (
                        <div className='characteristic-row'>
                            <TextField
                                value={characteristic[0]}
                                onChange={event => {
                                    const char = Array.from(characteristics)
                                    char[index][0] = event.target.value
                                    setCharacteristics(char)
        
                                    let errChar = Array.from(errorCharacteristics)
                                    if (errChar.indexOf(index) != -1) {
                                        errChar.splice(errChar.indexOf(index), 1)
                                    }
                                    setErrorCharacteristics(errChar)
                                }}
                                error={errorCharacteristics.indexOf(index) != -1}
                                id="outlined-basic"
                                label="Название"
                                variant="outlined"
                                style={{width: '48%', margin: '1rem 0'}}
                            />
        
                            <Typography style={{margin: '0 1rem'}}>-</Typography>
                            
                            <TextField
                                value={characteristic[1]}
                                onChange={event => {
                                    const char = Array.from(characteristics)
                                    char[index][1] = event.target.value
                                    setCharacteristics(char)
                                }}
                                id="outlined-basic"
                                label="Значение"
                                variant="outlined"
                                style={{width: '48%', margin: '1rem 0'}}
                            />
        
                            <Button onClick={() => {deleteCharacteristic(index)}} variant="contained" style={{backgroundColor: "#f44336", color: '#fff', marginLeft: '1rem'}}>
                                Удалить
                            </Button>
                        </div>
                    )
                })
            }
        
            <Button 
                onClick={addCharacteristic} 
                variant="contained"
                style={{backgroundColor: "#66bb6a", color: '#fff', display: 'block', margin: '2rem auto'}}
            > Добавить </Button>
        </>
    )
}

export default Characteristics