import { useContext, useState } from 'react'

import { SettingsContext } from "../../../Context/SettingsContext"
import ContactUs from '../../forms/ContactUs';

import { YMaps, Map, Placemark } from "react-yandex-maps";

import './index.sass'

const Contacts = () => {
    const settings = useContext(SettingsContext) 
    const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false)

    return (
        <>
            <section className="contacts">
                <div className="container">
                    {/* <SectionTitle title='КОНТАКТЫ' /> */}

                    <div className="contacts__content">
                        <div className="contacts__text">
                            <span className="contacts__title">ООО “МАГИСТРАЛЬ АВТО”</span>
                            <span className="contacts__address">{settings.address}</span>
                            <span className="contacts__tel">Телефон: <a href={"tel:" + settings.tel}>{settings.tel}</a></span>
                            <span className="contacts__email">Почта: <a href={"mailto:" + settings.email}>{settings.email}</a></span>

                            <div 
                                className="button contacts__btn"
                                onClick={() => {setContactUsDialogOpen(true)}}
                            >СВЯЗАТЬСЯ С НАМИ</div>
                        </div>

                        <div className="contacts__map" >
                            <YMaps>
                                <Map
                                    state={{
                                        center: [settings.latitude, settings.longitude],
                                        zoom: 13,
                                    }}
                                    defaultState={{
                                        center: [settings.latitude, settings.longitude],
                                        zoom: 13,
                                    }}
                                    width="100%"
                                    height="100%"
                                >
                                    <Placemark
                                        geometry={[settings.latitude, settings.longitude]}
                                        defaultProperties={{
                                            balloonContent: "Мы",
                                        }}
                                        modules={["geoObject.addon.balloon"]}
                                    />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
                <ContactUs
                    open={contactUsDialogOpen}
                    onClose={() => {setContactUsDialogOpen(false)}}
                />
            </section>
        </>
    )
}

export default Contacts