import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Header from "../../../components/Header"
import Container from "@material-ui/core/Container"
import { Button } from '@material-ui/core';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useHttp } from "../../../../hooks/http.hook";
// import "./index.sass"

const CharacteristicsTemplate = () => {
    const { request } = useHttp();

    const [templates, setTemplates] = useState([])

    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [pretenderRemoval, setPretenderRemoval] = useState(null);

    const handleOpenDialogDelete = (template) => {
        setPretenderRemoval(template)
        setOpenDialogDelete(true);
    };

    const handleCloseDialogDelete = () => {
        setOpenDialogDelete(false);
    };

    async function deleteTemplate() {
        handleCloseDialogDelete()
        await request('/characteristics-template/', "DELETE", {id: pretenderRemoval._id})
        await getTemplates()
    }

    async function getTemplates() {
        let data = await request('/characteristics-template/', "GET")
        console.log(data)
        setTemplates(data)
    }

    useEffect(() => {
        getTemplates()
    }, [])

    return (
        <>
            <Header title="Шаблоны характеристик">
                <Link to='/admin/characteristics-template/create'>
                    <Button variant="contained" style={{backgroundColor: "#66bb6a", color: '#fff', marginLeft: '1rem'}}>
                        Добавить
                    </Button>
                </Link>
            </Header>

            <Container style={{paddingTop: '2rem'}}>
                {
                    templates.map(template => {
                        return (
                            <Accordion key={template._id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <Typography>{template.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Характеристика</TableCell>
                                                    <TableCell align="right">Значение по уполчанию</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {Object.entries(template.values).map((row) => (
                                                <TableRow
                                                    key={row[0]}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                <TableCell component="th" scope="row">
                                                    {row[0]}
                                                </TableCell>
                                                <TableCell align="right">{row[1]}</TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>

                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}}>
                                    <Link to={'/admin/characteristics-template/' + template._id}>
                                        <Button variant="contained" style={{backgroundColor: "#66bb6a", color: '#fff', margin: '0 1rem 1rem 0', display: 'block'}}>
                                            Изменить
                                        </Button>
                                    </Link>
                                    <Button onClick={() => {handleOpenDialogDelete(template)}} variant="contained" style={{backgroundColor: "#f44336", color: '#fff', margin: '0 1rem 1rem 1rem', display: 'block'}}>
                                        Удалить
                                    </Button>
                                </div>
                            </Accordion>
                        )
                    })
                }
                
            </Container>

            <Dialog
                open={openDialogDelete}
                onClose={handleCloseDialogDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Удаление шаблона {pretenderRemoval && pretenderRemoval.name}
                </DialogTitle>
                <DialogContent>
                    Уверены, что хотите удалить шаблон "{pretenderRemoval && pretenderRemoval.name}"?
                </DialogContent>
                <DialogActions>

                    <Button 
                        onClick={deleteTemplate} 
                        variant="contained"
                        style={{backgroundColor: "#66bb6a", color: '#fff'}}
                    > Да </Button>

                    <Button 
                        onClick={handleCloseDialogDelete} 
                        variant="contained"
                        style={{backgroundColor: "#f44336", color: '#fff'}}
                    > Назад </Button>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default CharacteristicsTemplate