import { useState, useEffect, useRef } from 'react'

import Header from "../../../components/Header"
import Characteristics from '../../../components/Characteristics';
import Container from "@material-ui/core/Container"
import GallerySwiper from 'react-gallery-swiper';
import { Button } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography';

import { useHttp } from "../../../../hooks/http.hook";

import NoImg from '../../../../images/no-pictures.png'
import './index.sass'

const Product = () => {
    const { request } = useHttp();

    // Состаяния для изображений
    const [images, setImages] = useState([])        // Список имён файлов изображений
    const [imgSlides, setImgSlide] = useState([])   // Список объектов подготовленных для слайдера на основе images

    useEffect(() => {
        let imgs = []
        images.forEach(imgUrl => {
            imgs.push({
                original: '/uploads/' + imgUrl,
                thumbnail: '/uploads/' + imgUrl,
                originalClass: 'featured-slide',
                thumbnailClass: 'featured-thumb',
            })
        });
        setImgSlide(imgs)
    }, [images])

    const [indexSlide, setIndexSlide] = useState(0)

    const deleteImage = () => {
        const imgs = Array.from(images)
        imgs.splice(indexSlide, 1)
        setImages(imgs)
    }
    // Диалог для загрузки изображений
    const inputImageRef = useRef(null)
    const [openDialogLoadImage, setOpenDialogLoadImage] = useState(false);

    const handleClickOpenDialogLoadImage = () => {
        setOpenDialogLoadImage(true);
    };

    const handleCloseDialogLoadImage = () => {
        setOpenDialogLoadImage(false);
    };

    const loadImage = async (event) => {
        const formData = new FormData();
        
        const file = inputImageRef.current.children[0].firstChild.files[0]
        if (file) {
            formData.append("image", file);
            try {
                const img = await request("/product/add-image", "POST", formData, true);
                
                setImages([...images, img.filename])
            } catch (e) {
                console.log(e)
            }
            
            handleCloseDialogLoadImage()
        }
        
    }

    // Контроль полей ввода
    const [title, setTitle] = useState('')
    const [errorTitle, setErrorTitle] = useState(false)

    const [discription, setDiscription] = useState('')
    const [errorDiscription, setErrorDiscription] = useState(false)

    const [price, setPriced] = useState('')
    const [errorPrice, setErrorPrice] = useState(false)

    // Шаблоны характеристик
    const [characteristicsTamplates, setCharacteristicsTamplates] = useState([])                // Все шаблоны
    const [selectCharacteristicsTamplate, setSelectCharacteristicsTamplate] = useState(null)    // Выбранный

    const getCharacteristicsTamplates = async () => {
        let data = await request('/characteristics-template/', "GET")
        setCharacteristicsTamplates(data)
    }

    // Контроль компонента характеристик
    const [characteristics, setCharacteristics] = useState([['', '']])
    const [errorCharacteristics, setErrorCharacteristics] = useState([])

    const deleteCharacteristic = (index) => {
        const char = Array.from(characteristics)
        char.splice(index, 1)
        setCharacteristics(char)
    }

    const addCharacteristic = () => {
        const char = Array.from(characteristics)
        char.push(['', ''])
        setCharacteristics(char)
    }

    const isCharacteristicsValid = () => {
        let errChar = []
        characteristics.forEach((characteristic, index) => {
            if (characteristic[0].length === 0) {
                errChar.push(index)
            }
        })
        setErrorCharacteristics(errChar)

        return errChar.length === 0
    }
    

    async function getProduct() {
        const id = window.location.pathname.split('/')[3]
        const prod = await request('/product/' + id, "GET")
        const char = await request('/characteristics/' + id, "GET")

        setImages(prod.images)
        setTitle(prod.title)
        setDiscription(prod.discription)
        setPriced(prod.price)
        setCharacteristics(Object.entries(char.characteristics))
    }

    useEffect(async () => {
        await getProduct()
        await getCharacteristicsTamplates()
    }, [])

    const saveProduct = () => {
        let valid = isCharacteristicsValid()
        

        if (title.length === 0) {
            setErrorTitle(true)
            valid = false
        }

        if (valid) {
            let values = {}
            characteristics.forEach(characteristic => {
                values[characteristic[0]] = characteristic[1]
            })

            request('/product/', 'PUT', {
                id: window.location.pathname.split('/')[3],
                title, discription, price, images, 
                visibility: true,
                characteristics: values
            })
            window.location.href = '/admin/catalog/'
        }
    }

    return (
        <>
            <Header title="Product"/>
            <Container>
                <section style={{display: 'flex', justifyContent: 'space-between', minHeight: '100vh'}}>
                    <div className='product-gallery'>
                        { 
                            imgSlides.length !== 0 ? 
                            <GallerySwiper images={imgSlides} onSlide={(index) => {setIndexSlide(index)}}/> : 
                            <div class='no-images'>
                                <img src={NoImg} alt="" />
                                <div>Нет изображений</div>
                            </div>
                        }
                        
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button 
                                onClick={ handleClickOpenDialogLoadImage }
                                variant="contained" 
                                style={{backgroundColor: "#66bb6a", color: '#fff', margin: '1rem'}}
                            >
                                Добавить
                            </Button>

                            {
                                imgSlides.length !== 0 &&
                                <Button 
                                    onClick={ () => {deleteImage()} }
                                    variant="contained" 
                                    style={{backgroundColor: "#f44336", color: '#fff', margin: '1rem'}}
                                >
                                    Удалить
                                </Button>
                            }
                        </div>

                        <Dialog
                            open={openDialogLoadImage}
                            onClose={handleCloseDialogLoadImage}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                            {"Выберете файл"}
                            </DialogTitle>
                            <DialogContent>
                                <TextField ref={inputImageRef} id="outlined-basic" variant="outlined" type='file'/>
                            </DialogContent>
                            <DialogActions>

                                <Button 
                                    onClick={(event) => {loadImage(event)}} 
                                    variant="contained"
                                    style={{backgroundColor: "#66bb6a", color: '#fff'}}
                                > Загрузить </Button>

                                <Button 
                                    onClick={handleCloseDialogLoadImage} 
                                    variant="contained"
                                    style={{backgroundColor: "#f44336", color: '#fff'}}
                                > Назад </Button>

                            </DialogActions>
                        </Dialog>
                    </div>

                    <div className='main-content'>
                        <TextField
                            value={title}
                            onChange={event => {
                                setTitle(event.target.value)
                                setErrorTitle(false)
                            }}
                            error={errorTitle}
                            id="outlined-basic"
                            label="Название"
                            variant="outlined"
                        />
                        
                        <TextareaAutosize
                            value={discription}
                            onChange={event => {
                                setDiscription(event.target.value)
                                setErrorDiscription(false)
                            }}
                            error={errorDiscription}
                            aria-label="discription"
                            placeholder="Описание"
                            style={{ minWidth: '100%', maxWidth: '100%', minHeight: '100%', margin: '2rem 0' }}
                        />

                        <TextField
                            value={price}
                            onChange={event => {
                                setPriced(event.target.value)
                                setErrorPrice(false)
                            }}
                            error={errorPrice}
                            id="outlined-basic"
                            label="Цена"
                            variant="outlined"
                        />

                    </div>
                </section> 

                <section>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2rem'}}>
                        <Typography> Характеристики </Typography>
                        <TextField
                            id="outlined-basic"
                            select
                            label="Шаблон для характеристик"
                            value={selectCharacteristicsTamplate}
                            onChange={(event) => {
                                const index = event.target.value
                                setSelectCharacteristicsTamplate(index);
                                setCharacteristics(Object.entries(characteristicsTamplates[index].values))
                            }}
                            helperText="Выбрать шаблон характеристик"
                            variant="outlined"
                            style={{width: '35%'}}
                        >
                            {characteristicsTamplates.map((option, index) => (
                                <MenuItem key={option.value} value={index}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>

                    <Characteristics
                        characteristics={characteristics}
                        setCharacteristics={setCharacteristics}
                        errorCharacteristics={errorCharacteristics}
                        setErrorCharacteristics={setErrorCharacteristics}
                        deleteCharacteristic={deleteCharacteristic}
                        addCharacteristic={addCharacteristic}
                    />
                </section>

                <Button 
                    onClick={saveProduct} 
                    variant="contained"
                    style={{backgroundColor: "#66bb6a", color: '#fff', display: 'block', margin: '4rem 0 3rem auto', padding: '1rem 3rem'}}
                > Сохранить </Button>
                
            </Container>
        </>
    )
}

export default Product