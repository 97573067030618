import { useEffect, useState } from "react"
import Header from '../components/Header/Header'
import Footer from '../components/Footer'
import SectionTitle from "../components/SectionTitle"
import GallerySwiper from 'react-gallery-swiper';
import { Link } from "react-router-dom"
import './index.sass'
import { useHttp } from "../../hooks/http.hook"

import NoImg from '../../images/no-pictures.png'

import Order from '../forms/Order'

export const Product = () => {
    const { request } = useHttp();

    // Состаяния для изображений
    const [images, setImages] = useState([])        // Список имён файлов изображений
    const [imgSlides, setImgSlide] = useState([])   // Список объектов подготовленных для слайдера на основе images

    useEffect(() => {
        let imgs = []
        images.forEach(imgUrl => {
            imgs.push({
                original: '/uploads/' + imgUrl,
                thumbnail: '/uploads/' + imgUrl,
                originalClass: 'featured-slide',
                thumbnailClass: 'featured-thumb',
            })
        });
        setImgSlide(imgs)
    }, [images])

    const [indexSlide, setIndexSlide] = useState(0)

    async function getProduct() {
        const id = window.location.pathname.split('/')[2]
        const prod = await request('/product/' + id, "GET")
        const char = await request('/characteristics/' + id, "GET")

        setImages(prod.images)
        setTitle(prod.title)
        setDiscription(prod.discription)
        setPriced(prod.price)
        setCharacteristics(Object.entries(char.characteristics))
    }

    const [title, setTitle] = useState('')
    const [discription, setDiscription] = useState('')
    const [price, setPriced] = useState('')
    const [characteristics, setCharacteristics] = useState([])

    const [orderDialogOpen, setOrderDialogOpen] = useState(false)

    useEffect(async () => {
        await getProduct()
    }, [])

    return (
        <div className="page">
            <div>
                <Header/>
                <div className="container" style={{marginTop: "2.3rem"}}>
                    <Link to='/catalog'>
                        <SectionTitle title='КАТАЛОГ ТЕХНИКИ' />
                    </Link>
                </div>
            </div>
            <main>
                <div className="container">
                    {
                        !!title ? 
                        <div className="product">
                            <div className="product__title"> {title} </div>

                            <div className="product__swiper">
                            { 
                                imgSlides.length !== 0 ? 
                                <GallerySwiper 
                                    showThumbnails={false} 
                                    showBullets={true} 
                                    images={imgSlides} 
                                    onSlide={(index) => {setIndexSlide(index)}}
                                /> : 
                                <div class='no-images'>
                                    <img src={NoImg} alt="" />
                                    <div>Нет изображений</div>
                                </div>
                            }
                            </div>

                            <div className="product__discription"> {discription} </div>

                            <div className="product__characteristics">
                                {
                                    characteristics.map((characteristic) => {
                                        let [ key, value ] = characteristic
                                        return (
                                            <div className="product__characteristic" key={ key }>
                                                <span>{ key }</span>: <span>{ value }</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="product__buttons">
                                <button 
                                    className="button product__button product__order-btn" 
                                    onClick={() => {setOrderDialogOpen(true)}}
                                >
                                    ЗАКАЗАТЬ
                                </button>
                                <a className="button product__button product__pdf-btn" target="_blank" href={"/products-pdf/" + window.location.pathname.split('/')[2] + '.pdf'}>СКАЧАТЬ В PDF</a>
                            </div>
                        </div> :
                        <div className="product product--load"></div>
                    }
                        
                </div>
                <Order 
                    model={title}
                    open={orderDialogOpen}
                    onClose={() => {setOrderDialogOpen(false)}}
                />
            </main>
            <Footer />
        </div>
    )
}